
  import { Utils } from '@/scripts/Utils';
  import { Component, Vue } from 'vue-property-decorator';

  /**
   * @desc composant proposant une interface permettant à l'utilisateur de selectionner des dates de filtrage des articles
   */
  @Component({
    components: {},
  })
  export default class FilterDatesSelector extends Vue {
    applyFilter = false;

    startDate : Date = new Date();
    endDate : Date = new Date();
    strStartDate : string = this.toTextFieldDate(new Date());
    strEndDate : string = this.toTextFieldDate(new Date());

    /**
     * @desc transforme une Date en un string lisible pour la zone de texte : yyyy-mm-dd
     */
    private toTextFieldDate(date: Date) : string {
      let month = (date.getMonth()+ 1).toString();
      if(month.length === 1) month = "0" + month;
      let day = date.getDate().toString();
      if(day.length === 1) day = "0" + day;

      return date.getFullYear().toString() + '-' + month + '-' + day;
    }

    /**
     * @desc met à jour la date de fin indiquée(doit rester supérieure à la date de début), et demande l'ajout du filtre
     */
    private dateChanged() {
      this.triggerFocusOut((this.$refs.endDateField as any));
      setTimeout(() => {
        const startDate = new Date(this.strStartDate);
        let endDate = new Date(this.strEndDate);

        if(endDate < startDate){
          endDate = startDate;
          this.strEndDate = this.toTextFieldDate(startDate);
        }

        if(this.applyFilter && this.strStartDate !== this.strEndDate && endDate > startDate) {
          this.$emit("filterDate", true, startDate, endDate);
        }
      }, 2);
    }

    /**
     * @desc appel la déselection de l'élément indiqué
     */
    private triggerFocusOut(el : any) {
      el.focus();
      el.blur();
    }

    /**
     * @desc indique que les filtres par dates doivent être appliqués / enlevés
     */
    private applyChanged() : void {
      if(this.applyFilter) {
        this.dateChanged();
      }
      else {
        this.$emit("filterDate", false);
      }
    }
  }
