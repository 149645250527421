import { ArticleEditionDto } from "./ArticleEditionDto.entity";

/**
 * @desc classe d'article, pour l'envoi et la reception d'articles au serveur
 */
export class Article {
  constructor(data ? : any) {
    this.id = data && data.id || 0;
    this.imgs = data && data.imgs || [];
    this.title = data && data.title || "";
    this.summary = data && data.summary || "";
    this.textContent = data && data.textContent || "";
    this.authorName = data && data.authorName || "";
    this.authorId = data && data.authorId || "";
    this.rubrics = data && data.rubrics || [];
    this.coverImgId = data && data.coverImgId || 0;
    this.createdAt = data && new Date(data.createdAt) || undefined;

    this.validated = data && data.validated || undefined;
  }

  id: number;
  imgs?: Array<number> | Array<ArticleImageDto>; // ids
  title: string;
  summary: string;
  textContent: string;
  authorName?: string;
  authorId?: string;
  rubrics: Array<number> | Array<ArticleRubricDto>;

  coverImgId? : number;
  createdAt? : Date;
  validated?: boolean;
  
  /**
   * @desc copie l'article et en renvoie un nouveau identique
   */
  public clone(): Article {
    const cloneObj = new Article() as any;
    for (const attribut in this) {
      cloneObj[attribut] = this[attribut];
    }
    return cloneObj;
  }

  /**
   * @desc créé un object ArticleEditionDto contenant uniquement les propriétés qui diffèrent de l'article indiqué
   * @param baseArticle 
   * @returns 
   */
  public createArticleEditionDto(baseArticle : Article) : ArticleEditionDto{
    if(!baseArticle.id) return new ArticleEditionDto();

    const data : any = {};
    data.id = baseArticle.id;

    if(baseArticle.imgs !== this.imgs){
      if(baseArticle.imgs && this.imgs) {
        if(!this.sameImgs(baseArticle.imgs as Array<ArticleImageDto>, this.imgs as Array<ArticleImageDto>))
          data['imgs'] = this.imgs;
      }
      else
        data['imgs'] = this.imgs;
    }
    if(baseArticle.title !== this.title)
      data['title'] = this.title;
    if(baseArticle.summary !== this.summary)
      data['summary'] = this.summary;
    if(baseArticle.textContent !== this.textContent)
      data['textContent'] = this.textContent;
    if(baseArticle.authorName !== this.authorName)
      data['authorName'] = this.authorName;
    if(baseArticle.rubrics !== this.rubrics) {
      if(!this.sameRubricArrays(baseArticle.rubrics, this.rubrics))
        data['rubrics'] = this.rubrics;
    }
    if(baseArticle.coverImgId !== this.coverImgId)
      data['coverImgId'] = this.coverImgId;

    return new ArticleEditionDto(data);
  }

  /**
   * @desc vérifie si les tableaux de rubrique sont identiques
   */
  private sameRubricArrays(array1 : Array<any>, array2 : Array<any>) : boolean {
    if(array1.length !== array2.length) return false;
    for(let i=0; i < array1.length; ++i)
      if(!array2.includes(array1[i]) && !array2.includes(array1[i].id))
        return false;
    return true;
  }

  /**
   * @desc vérifie si les images des tableaux sont identiques
   * /!\ L'ordre des tableaux à une importance
   * @param array1 tableau des images avant modifications de l'article
   * @param array2 tableau des images après modification de l'article
   */
  private sameImgs(array1 : Array<ArticleImageDto>, array2 : Array<ArticleImageDto>): boolean {
    if(array1.length !== array2.length) return false;
    if(array2.find(x => x.deletion)) return false;
    if(array2.find(x => x.rotation)) return false;

    for(let i=0; i < array1.length; ++i) {
      if(!array2.find(x => x.id === array1[i].id && x.order === array1[i].order)) {
        return false;
      }
    }
    return true;
  }
}

/**
 * @desc les données reçues venant du serveur au sujet d'une rubrique
 */
export class ArticleRubricDto {
  constructor(data?: any) {
    this.id = data.id || 0;
    this.name = data.name || "";
  }

  id: number;
  name: string;
} 

/**
 * @desc données envoyées au serveur pour transmettre une image associée à un article
 */
export class ArticleImageDto {
  constructor(data?: any) {
    this.id = data.id || 0;
    this.order = data.order || 0;
    this.rotation = data.rotation || undefined;
    this.deletion = data.deletion || false;
  }

  id: number;
  order: number;
  rotation?: number;
  deletion?: boolean;
} 