
  import { Filter } from '@/interfaces/Filter';
  import { AuthenticationService } from '@/services/authentication.service';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

  /**
   * @desc section de filtre contenant plusieurs filtres !
   */
  @Component({
    components: {},
  })
  export default class FilterSection extends Vue {

    // titre du filtre
    @Prop({default: "filtre"})
    title? : string;

    // liste des filtres
    @Prop({default: []})
    filters? : Filter[];

    // liste des filtres selectionnés
    selectedFilters: string[] = [];

    /**
     * @desc ajoute / retire le filtre indiqué de la liste des filtre
     */
    private filterClick(filterKey: string) : void {
      const index = this.selectedFilters.findIndex(x => x === filterKey);
      if(index > -1) {
        this.selectedFilters.splice(index, 1);
        this.$emit("filterSelected", filterKey, false);
      }
      else {
        this.selectedFilters.push(filterKey);
        this.$emit("filterSelected", filterKey, true);
      }

    }

    /**
     * @desc retourne si oui ou non un filtre est selectionné
     */
    private filterSelected(filterKey: string) : boolean {
      return this.selectedFilters.includes(filterKey);
    }
  }
