
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import axios from 'axios';
  import { AuthenticationService } from '../services/authentication.service';
  import * as UrlConsts from '@/scripts/UrlConsts';


  /**
  * @desc Composant qui remplace une image html, auquel ont fourni une src ou un id,
  *  qui envoie une requète au serveur pour demander le fichier associé
  */
  @Component({
    components: {
    },
  })
  export default class CustomImage extends Vue {
    
    /**
     * @desc class globale css à appliquer à l'image
     */
    @Prop()
    childclass? : string | undefined;

    // requète différente en fonction de si c'est une image d'article ou autre
    @Prop()
    articleImg? : boolean | undefined;

    @Prop({default: 0})
    imgId? : number;

    @Prop({default: ''})
    customSrc? : string;

    @Prop({default: true})
    defaultImg? : boolean;

    /**
     * @desc nom de l'événement à emmettre quand l'image est chargée
     */
    @Prop({default: "c-img-loaded"})
    onloadEvent? : string;

    private currentId = 0;
    private currentSrc = "";
    stateClass = '';

    mounted() : void{
      if(this.imgId){
        this.setId(this.imgId);
      }
    }

    /**
     * @desc mise à jour de l'image affichée
     */
    @Watch("imgId")
    private imgIdChanged() {
      if(this.imgId) {
        this.setId(this.imgId);
      }
    }

    @Watch("customSrc")
    private imgSrcChanged() {
      if(!this.imgId) {
        this.setSrc(this.customSrc as string);
      }
    }


    /**
     * @desc défini l'id de l'image, et lance la requète au serveur
     */
    public setId(imgId : number) : void {
      this.currentId = imgId;
      this.loadImage();
    }

    /**
     * @desc requète serveur de l'image associée au currentId
     */
    private loadImage() : void {
      if(this.currentId < 1){
        this.setSrc("");
        return;
      }
      this.setSrc("");

      const headers = AuthenticationService.getRequestHeader();
      const url =   this.articleImg ? UrlConsts.getArticleImg : UrlConsts.getImg;
      axios
        .get(url + '/' + this.currentId, headers)
        .then((response) => {
          let urlCreator = window.URL || window.webkitURL;
          let blobData = new Blob([new Uint8Array(response.data.data)]);
          let imageUrl = urlCreator.createObjectURL(blobData);
          this.setSrc(imageUrl);
        })
        .catch(error => console.log(error))
    }

    /**
     * @desc défini la source de l'image à montrer -> pas de requète serveur
     */
    public setSrc(src : string) : void {
      if(src !== this.currentSrc) {
        this.currentSrc = src;
        if(!this.currentSrc) {
          (this.$el.querySelector("#img") as HTMLImageElement).removeAttribute("src");
          if(!this.imgId) {
            this.stateClass = 'hide';
          }
        }
        else {
          (this.$el.querySelector("#img") as HTMLImageElement).src = src;
          this.stateClass = '';
        }         

      }
    }

    /**
     * @desc renvoie la src de l'image affichée
     */
    public getSrc() : string {
      return this.currentSrc as string;
    }

    /**
     * @desc emmission d'un événement quand l'image est chargée
     */
    private imgLoaded(event : Event) : void {
      const img = event.target as HTMLImageElement;
      if(img)
        img.style.display = "block";

      if(!this.onloadEvent) return;
      this.$emit('loaded', img);
    }

    /**
     * @desc cache l'image en cas d'érreur lors du chargement
     */
    private onError(event : Event) : void {
      const img = event.target as HTMLImageElement;
      if(img)
        img.style.display = "none";
    }
  }
