
  import { ArticleFiltersDto } from '@/interfaces/ArticleFiltersDto';
  import { AuthenticationService } from '@/services/authentication.service';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import FilterSection from './FilterSection.component.vue';
  import { Filter } from '@/interfaces/Filter';
  import axios from 'axios';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import { Utils } from '@/scripts/Utils';
  import FilterDatesSelector from './FilterDatesSelector.component.vue';
  import SearchBar from './SearchBar.component.vue';
  import { Rubric } from '@/interfaces/Rubric.interface';
  import * as Consts from '@/scripts/Consts';


  const CLASS_FILTER_SECTION = 0;
  const PROJECT_FILTER_SECTION = 1;
  const YEAR_FILTER_SECTION = 2;

  /**
   * @desc composant rassemblant toutes les possibilités de filtrage des articles
   */
  @Component({
    components: {
      'filter-section' : FilterSection,
      'filter-dates-selector' : FilterDatesSelector,
      'search-bar' : SearchBar
    },
  })
  export default class ArticleFilter extends Vue {
    // les filtres que l'utilisateur peut selectionner
    filteringData = [
       {
        title: "Par classe",
        filters: new Array<Filter>()
      },
       {
        title: "Par projet d'école",
        filters: new Array<Filter>()
      },
      {
        title: "Par année",
        filters: new Array<Filter>()
      },
    ];

    // les filtres selectionnés par l'utilisateur
    selectedFilters : ArticleFiltersDto = new ArticleFiltersDto();

    mounted() : void {
      this.init();
    }

    /**
     * @desc initialisation des filtres
     */
    private async init() : Promise<void> {
      this.initYearFilters();
      await this.loadRubrics();
    }

    /**
     * @desc charge les rubriques selectionnables
     */
    private async loadRubrics() : Promise<void> {
      const headers = AuthenticationService.getRequestHeader();

      return new Promise((resolve, reject) => {
        axios
          .get(UrlConsts.getAllRubrics, headers)
          .then((response) => {
            const now = new Date();
            const rubrics : Rubric[] = response.data;
            const archivedProjects = [];
            const archivedClasses = [];
            for(let i=0; i < rubrics.length; ++i) {
              if(rubrics[i].type === Consts.rubric_type_schoolProject) {
                if(new Date(rubrics[i].archivedDate) < now) {
                  archivedProjects.push(new Filter({title:  this.getRubricFullName(rubrics[i]), key: `rubric_${rubrics[i].id}`}));
                }
                else this.filteringData[PROJECT_FILTER_SECTION].filters.push(new Filter({title:  this.getRubricFullName(rubrics[i]), key: `rubric_${rubrics[i].id}`}));
              }
              else if(rubrics[i].type === Consts.rubric_type_classroom) {
                  if(new Date(rubrics[i].archivedDate) < now) {
                    archivedClasses.push(new Filter({title: this.getRubricFullName(rubrics[i]), key: `rubric_${rubrics[i].id}`}));
                  }
                  else this.filteringData[CLASS_FILTER_SECTION].filters.push(new Filter({title:  this.getRubricFullName(rubrics[i]), key: `rubric_${rubrics[i].id}`}));
  
              }
            }

            if(archivedProjects.length) this.filteringData[PROJECT_FILTER_SECTION].filters.push(
              new Filter({title: '-- archivés --', key: '', children: archivedProjects})
            );
            if(archivedClasses.length) this.filteringData[CLASS_FILTER_SECTION].filters.push(
              new Filter({title: '-- archivées --', key: '', children: archivedClasses})
            );
            resolve();
          })
          .catch(error => {console.log(error); reject(error)})
      });
    }

     
    /**
     * @desc initialise les années de filtrage selectionnables
     */
    private initYearFilters() : void {
      const beginYear = Consts.startYear;
      const now = new Date();
      let endYear = new Date().getFullYear();
      if(now.getMonth()+1 >= 8) ++endYear;

      for(let i =endYear; i > beginYear; i--) {
        this.filteringData[YEAR_FILTER_SECTION].filters.push(
          new Filter({title: `${i-1}-${i}`, key: 'year_' + i.toString()})
        );
      }
    }

    /**
     * @desc ajoute un filtre par dates aux filtres selectionnés
     * @param applyFilter si oui ou non les filtres par date doivent être appliqués
     */
    public setDateFilter(applyFilter: boolean, startDate : Date, endDate: Date) : void {
      if(applyFilter) {
        this.selectedFilters.startDate = startDate;
        this.selectedFilters.wantedEndDate = endDate;
      }
      else {
        this.selectedFilters.startDate = undefined;
        this.selectedFilters.wantedEndDate = undefined;
      }
      this.updateArticles();
    }

    /**
     * @desc ajoute un filtre par texte aux filtres selectionnés
     */
    public setSearchTextFilter(text : string) : void {
      this.selectedFilters.searchText = text;
      this.updateArticles();
    }


    /**
     * @desc ajoute un filtre de rubrique ou de date aux filtres selectionnés
     */
    public setFilter(key:string, value : boolean) : void {
      const splittedKey = key.split("_");

      if(splittedKey[0] === "rubric") {
        if(value) {
          if(!this.selectedFilters.rubrics)
            this.selectedFilters.rubrics = [];
          this.addToArray(this.selectedFilters.rubrics, parseInt(splittedKey[1]));
        }
        else if(this.selectedFilters.rubrics) {
          this.removeFromArray(this.selectedFilters.rubrics, parseInt(splittedKey[1]));
        }
      }
      else if(splittedKey[0] === "year") {
        if(value) {
          if(!this.selectedFilters.years)
            this.selectedFilters.years = [];
          this.addToArray(this.selectedFilters.years, parseInt(splittedKey[1]));
        }
        else if(this.selectedFilters.years) {
          this.removeFromArray(this.selectedFilters.years, parseInt(splittedKey[1]));
        }
      }
      this.updateArticles();
    }

    /**
     * @desc enlève l'élément indiqué du tableau indiqué
     */
    private removeFromArray(array : Array<any>, value : any) : void{
      const index = array.findIndex(x => x === value);
      if(index > -1)
        array.splice(index, 1);
    }

    /**
     * @desc ajoute l'élément indiqué dans le tableau indiqué s'il n'y est pas
     */
    private addToArray(array : Array<any>, value : any) : void{
      const index = array.findIndex(x => x === value);
      if(index === -1)
        array.push(value);
    }


    private archiveExclusionChanged() : void {
      this.updateArticles();
    }

    /**
     * @desc demande la mise à jour du filtrage des articles
     */
    public updateArticles() : void {
      if(!this.selectedFilters.searchText) this.selectedFilters.searchText = undefined;
      if(this.selectedFilters.rubrics && !this.selectedFilters.rubrics.length)
        this.selectedFilters.rubrics = undefined;
      if(this.selectedFilters.years && !this.selectedFilters.years.length)
        this.selectedFilters.years = undefined;
      if(!this.selectedFilters.unarchived)
        this.selectedFilters.unarchived = undefined;
      this.$emit('filtersUpdate', this.selectedFilters);
    }

    /**
     * @desc renvoie le nom complet de la rubrique
     */
    private getRubricFullName(rubric : Rubric) : string {
      return Utils.getRubricFullName(rubric.startDate, rubric.endDate, rubric.name);
    }
  }
