
  import { Rubric } from '@/interfaces/Rubric.interface';
import { Utils } from '@/scripts/Utils';
  import { Component, Prop, Vue } from 'vue-property-decorator';

  /**
   * @desc affiche en ligne les rubriques indiquées
   */
  @Component({
    components: {
    },
  })
  export default class ArticleRubricList extends Vue {
    
    @Prop({default: []})
    rubrics? : Array<Rubric>;

    @Prop({default: "start"})
    alignClass? : string;

    /**
     * @desc navigue vers la page d'affichage de la rubrique indiquée
     */
    private showRubric(rubricId : number) : void {
      const query = Utils.createUrlQuery({id: rubricId});
      this.$router.push('/rubriques/articles/' + rubricId);
    }

    /**
     * @desc renvoie le nom de la rubrique complet
     */
    private getRubricFullName(rubric : Rubric) : string {
      return Utils.getRubricFullName(rubric.startDate, rubric.endDate, rubric.name);
    }
  }
