
  import { Component, Vue } from 'vue-property-decorator';

  /**
   * @desc composant qui joue le rôle d'une boite
   */
  @Component({
    components: {},
  })
  export default class Container extends Vue {
      
  }

