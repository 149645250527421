
  import { Component, Vue } from 'vue-property-decorator';
  import NavHeader from '@/components/NavHeader.component.vue'; // @ is an alias to /src

  /**
   * @desc image d'en tête du site
   */
  @Component({
    components: {
      PageHeader,
      NavHeader,
    },
  })
  export default class PageHeader extends Vue {}

