
  import { Article } from '@/interfaces/arcticles/Article.entity';
  import { AuthenticationService } from '@/services/authentication.service';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import ArticleSum from './ArticleSum.component.vue';

  /**
   * @desc boite de dialogue pour confirmer la quelque chose
   */
  @Component({
    components: {
      'article-sum' : ArticleSum
    },
  })
  export default class ArticleList extends Vue {
    @Prop({default: []})
    articleList? : Array<Article>;

    @Prop({default: "all"})
    display?: string;

    @Prop({default: 50})
    displayCount?: number;

    @Prop({default: false})
    stopScrolling?: boolean;

    private identified = false;

    private displayedArticles: Array<Article> = [];
    private lastDisplayedIndex = 0;
    private lastDisplayedDate = new Date();


    mounted() {
      this.initDisplay();
      this.identified = AuthenticationService.isLoggedIn();
    }

    /**
     * @desc met à jour l'affichage de la liste
     */
    @Watch("articleList")
    private articleListUpdate() {
      this.initDisplay();
    }

    /**
     * @desc initialise l'affichage de la liste
     */
    private initDisplay() : void {
      this.displayedArticles = [];
      this.lastDisplayedIndex = 0;
      this.displayArticles();
    }

    /**
     * @desc affiche les articles de la liste donnée
     */
    private displayArticles() : void {
      if(!this.articleList) {
         this.displayedArticles = [];
         return;
      }

      this.lastDisplayedIndex = this.articleList.length;
    
      this.displayedArticles = this.articleList;
      if(this.displayedArticles.length)
        this.lastDisplayedDate = this.displayedArticles[this.displayedArticles.length-1].createdAt || new Date();
    }

    /**
     * @desc indique aux parents que l'utilisateur a scrollé en bas de la liste
     */
    private infiniteScrolling() {
      if(this.stopScrolling) return;

      this.$emit('loadArticles', this.lastDisplayedDate);
    }
  }
