

export const user_status_classroom = 1;
export const user_status_parents = 2;
export const user_status_teacher = 3;
export const user_status_admin = 4;

export const rubric_type_schoolProject = 1;
export const rubric_type_classroom = 2;

// plus ancienne année d'existence de la base de donnée
export const startYear = 2013;
