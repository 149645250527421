
  import { ArticleFiltersDto } from '@/interfaces/ArticleFiltersDto';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import ArticleFilter from './ArticleFilter.component.vue';

  const rubricType = 1;
  const classType = 2;

  /**
   * @desc boite d'outils en navigation drawer pour l'affichage mobile des filtres
   */
  @Component({
    components: {
      'article-filter' : ArticleFilter
    },
  })
  export default class MobileArticleFilter extends Vue {
    visible = false;

    @Prop()
    menuVisible? : boolean;

    /**
     * @desc affiche la boite de navigation
     */
    @Watch('menuVisible')
    showMenu() : void {
      this.visible = false;
      setTimeout(() => {
        this.visible = true;
        (this.$refs.articleFilter as any).updateArticles();
      }, 10);
    }

    public filtersUpdate(filteringData?: ArticleFiltersDto): void {
        this.$root.$emit('filtersUpdate', filteringData);
    }
  }

