
  import { Component, Vue } from 'vue-property-decorator';
  import { AuthenticationService } from '../services/authentication.service';
  import { HeaderMenu } from '../interfaces/HeaderMenu';
  import { Rubric } from '@/interfaces/Rubric.interface';
  import axios from 'axios';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import { Utils } from '@/scripts/Utils';
  import Container from './Container.component.vue';
  import UserAccount from './UserAccount.component.vue';
  import * as NavHeaderConsts from '@/scripts/NavHeaderConsts';
  import * as Consts from '@/scripts/Consts';

  const rubricType = 1;
  const classType = 2;

  /**
   * @desc barre de navigation pour l'affichage écran d'ordinateur
   */
  @Component({
    components: {
      'container': Container,
      'user-account' : UserAccount
    },
  })
  export default class NavHeader extends Vue {
    username = ''; // nom de l'utilisateur connecté
    userStatus = '';
    stick = false;

    idCount = 30;
    
    menus : Array<HeaderMenu> = [      
      new HeaderMenu({title: "Accueil", route:"/", id: 1}), 
      new HeaderMenu({title: "Articles", route:"/articles", id: 2}), 
      new HeaderMenu({title: "Classes", route:"/classes", id: 3}),
      new HeaderMenu({title: "Projets d'école", route:"/projets-ecole", id: 4}),
      new HeaderMenu({title: "Archives", route:"/archives", id: 5}),
      new HeaderMenu({title: "Contact", route:"/contact", id: NavHeaderConsts.CONTACT_MENU}),
    ]

    accountMenu : any = {
      title: "Connexion",
      route: "/connexion",
      content: false
    }

    mounted() :void {
      this.init();
      this.initStick();

      this.$root.$on('login', () => {
        this.initAccountMenu();
      });
      this.$root.$on('logout', () => {
        this.initAccountMenu();
      });
    }

    /**
     * @desc initialise les boutons
     */
    private init() : void{
      this.loadArticles();
      this.loadUnarchivedRubrics();
      this.initAccountMenu();
    }

    /**
     * @desc initialise la mise à jour de la propriété sticky de la barre de navigation
     */
    private initStick() : void {
      const stickyElm = (this.$refs.componentBox as Container).$el as Element;
      const observer = new IntersectionObserver( 
        ([e]) => {
          if(e.intersectionRatio < 1){
            this.stick = true;
            e.target.setAttribute('state', '2');
            setTimeout(() => {
              if(!this.stick) return;
              e.target.setAttribute('state', '3');
            }, 20);
          }
          else{
            this.stick = false;
            e.target.setAttribute('state', '4');
            setTimeout(() => {
              if(this.stick) return;
              e.target.setAttribute('state', '1');
            }, 400);
          }
        },
        
        {threshold: [1], rootMargin: "0px 2000px 0px 2000px"}
      );
      observer.observe(stickyElm);
    }

    /**
     * @desc initialise la barre de navigation en fonction de l'utilisateur connecté
     */
    private initAccountMenu() : void {
      this.resetMenus();
      if(AuthenticationService.isLoggedIn()) { // utilisateur connecté
        const userStatus = AuthenticationService.getUserStatus();
        switch(userStatus) {
         case Consts.user_status_classroom:  // en tant que classe
            this.showClassroomAccountMenu();
            break;
            
          case Consts.user_status_parents:  // en tant que parent
            this.showParentsAccountMenu();
            break;

          case Consts.user_status_teacher:  // en tant qu'enseignant
            this.showTeacherAccountMenu();
            break; 

          case Consts.user_status_admin:  // en tant qu'admin
            this.showAdminAccountMenu();
            break; 

          default:
            this.showUnloggedAccountMenu();
            break;
        }
      }
      else {
        this.showUnloggedAccountMenu();
      }
    }

    resetMenus() {
      this.menus = this.menus.filter(
        x => [1, 2, 3, 4, 5, 6].includes(x.id)
      );
    }

    /**
     * @desc charge les derniers articles publiés
     */
    private loadArticles() : void {
      const limitArticles = 5;
      const headers = AuthenticationService.getRequestHeader();
      const query = '?' + Utils.createUrlQuery({limit: limitArticles, startDate: new Date().toString()})

      axios
        .get(UrlConsts.getAllArticlesLimited + query, headers)
        .then((response) => {
          const articlesMenu = this.getMenu(NavHeaderConsts.ARTICLES_MENU);
          const articleMenus = [];
          for(let i=0; i < response.data.length; ++i) {
            ++this.idCount;
            articleMenus.push(
              new HeaderMenu(
                {title: response.data[i].title, id: this.idCount, route: `/article/${response.data[i].id}`}
              )
            );
          } 
          articlesMenu.children = articleMenus;
        })
        .catch(error => console.log(error))
    }


    /**
     * @desc charge les rubriques non archivées
     */
    private loadUnarchivedRubrics() : void {
      const headers = AuthenticationService.getRequestHeader();

      axios
        .get(UrlConsts.getAllUnarchivedRubrics, headers)
        .then((response) => {
          const rubrics = response.data as Rubric[];
          const rubricMenus = [];
          const classMenus = [];
          for(let i=0; i < rubrics.length; ++i) {
            if(rubrics[i].type === rubricType) {
              ++this.idCount;
              rubricMenus.push(
                new HeaderMenu(
                  {title: rubrics[i].name, id: this.idCount, route: `/rubriques/articles/${rubrics[i].id}`}
                )
              );
            }
            else if(rubrics[i].type === classType) {
              ++this.idCount;
              classMenus.push(
                new HeaderMenu(
                  {title: rubrics[i].name, id: this.idCount, route: `/rubriques/articles/${rubrics[i].id}`}
                )
              );
            }
          }

          const rubricsMenu = this.getMenu(NavHeaderConsts.PROJECTS_MENU);
          const classesMenu = this.getMenu(NavHeaderConsts.CLASSES_MENU);
          rubricsMenu.children = rubricMenus;
          classesMenu.children = classMenus;
        })
        .catch(error => console.log(error))
    }

    /**
     * @desc renvoie le menu demandé
     */
    private getMenu(id: number) : HeaderMenu {
      return this.menus.find(x => x.id === id) || new HeaderMenu();
    }

    /**
     * @desc ajoute un menu avant le menu indiqué
     */
    private insertMenuBefore(id: number, menu: HeaderMenu) : void {
      if(id === -1)
        this.menus.push(menu);
      else {
        const index = this.menus.findIndex(x => x.id === id);
        if(index > -1)
          this.menus.splice(index, 0, menu);
      }
       
    }
    
    /**
     * @desc supprime le menu indiqué
     */
    private removeMenu(id: number) : void {
      const index = this.menus.findIndex(x => x.id === id);
      if(index > -1)
        this.menus.splice(index, 1);
    }

    /**
     * @description montre le composant pour un utilisateur non-connecté
     */
    private showUnloggedAccountMenu() : void {
      this.accountMenu.title =  "Connexion";
      this.accountMenu.route = "/connexion";
      this.removeMenu(NavHeaderConsts.ADMIN_MENU);
      this.removeMenu(NavHeaderConsts.CLASS_MENU);

      this.username = '';
      this.userStatus = "";
      this.accountMenu.content = false;
    }

    /**
     * @description montre le composant pour un utilisateur connecté en tant qu'enseignant
     */
    private showTeacherAccountMenu() : void {
      this.accountMenu.title =  "Mon compte";
      this.accountMenu.route = undefined;

      const adminMenu = new HeaderMenu({title: "Administration", id: NavHeaderConsts.ADMIN_MENU, children: [
          new HeaderMenu({title: "Créer article", route: '/gestion/creer-article', id: NavHeaderConsts.ADMIN_CREATE_ARTICLE_MENU}),
          new HeaderMenu({title: "Ma classe", route: '/gestion/ma-classe', id: NavHeaderConsts.ADMIN_MY_CLASSROOM_MENU}),
          new HeaderMenu({title: "Projets d'école", route: '/gestion/projets-ecole', id: NavHeaderConsts.ADMIN_SCHOOL_PROJECTS_MENU}),
          new HeaderMenu({title: "Gestion des classes", route: '/gestion/classes', id: NavHeaderConsts.ADMIN_CLASSROOMS_MENU}),
          new HeaderMenu({title: "Gestion d'accueil", route: '/gestion/accueil', id: NavHeaderConsts.ADMIN_HOME_MENU}),
          new HeaderMenu({title: "Statistiques", route: '/gestion/statistiques', id: NavHeaderConsts.ADMIN_STAT_MENU})
        ]
      });
      this.insertMenuBefore(-1, adminMenu);

      this.username = AuthenticationService.getUsername();
      this.userStatus = "Enseignant";
      this.accountMenu.content = true;
    }


    /**
     * @description montre le composant pour un utilisateur connecté en tant qu'admin
     */
    private showAdminAccountMenu() : void {
      this.accountMenu.title =  "Mon compte";
      this.accountMenu.route = undefined;

      const adminMenu = new HeaderMenu({title: "Administration", id: NavHeaderConsts.ADMIN_MENU, children: [
          new HeaderMenu({title: "Projets d'école", route: '/gestion/projets-ecole', id: NavHeaderConsts.ADMIN_SCHOOL_PROJECTS_MENU}),
          new HeaderMenu({title: "Gestion des classes", route: '/gestion/classes', id: NavHeaderConsts.ADMIN_CLASSROOMS_MENU}),
          new HeaderMenu({title: "Gestion d'accueil", route: '/gestion/accueil', id: NavHeaderConsts.ADMIN_HOME_MENU}),
          new HeaderMenu({title: "Gestion des enseignants", route: '/gestion/enseignants', id: NavHeaderConsts.ADMIN_TEACHERS_MENU}),
          new HeaderMenu({title: "Gestion des parents", route: '/gestion/parents', id: NavHeaderConsts.ADMIN_PARENTS_MENU}),
          new HeaderMenu({title: "Statistiques", route: '/gestion/statistiques', id: NavHeaderConsts.ADMIN_STAT_MENU})
        ]
      });
      this.insertMenuBefore(-1, adminMenu);

      this.username = AuthenticationService.getUsername();
      this.userStatus = "Administrateur";
      this.accountMenu.content = true;
    }

    /**
     * @description montre le composant pour un utilisateur connecté en tant que classe
     */
    private showClassroomAccountMenu() : void {
      this.accountMenu.title =  "Mon compte";
      this.accountMenu.route = undefined;

      const classMenu = new HeaderMenu({title: "Création", id: NavHeaderConsts.CLASS_MENU, children: [
          new HeaderMenu({title: "Créer article", route: '/classe/creer-article', id: NavHeaderConsts.CLASS_CREATE_ARTICLE_MENU}),
          new HeaderMenu({title: "Mes articles", route: '/classe/mes-articles', id: NavHeaderConsts.ADMIN_MY_CLASSROOM_MENU}),
        ]
      });

      this.username = AuthenticationService.getUsername();
      this.userStatus = "Classe";
      this.accountMenu.content = true;

      this.insertMenuBefore(-1, classMenu);

      this.username = AuthenticationService.getUsername();
    }

    /**
     * @description montre le composant pour un utilisateur connecté en tant que parent
     */
    private showParentsAccountMenu() : void {
      this.accountMenu.title =  "Mon compte";
      this.accountMenu.route = undefined;

      this.username = AuthenticationService.getUsername();
      this.userStatus = "Parent";
      this.accountMenu.content = true;
    }

    /**
     * @description déconnecte l'utilisateur et l'envoie vers la page de connexion
     */
    private disconnect() :void  {
      AuthenticationService.logout();
      console.log("deconnexion");
      this.initAccountMenu();
      this.$router.push("/connexion").catch((err) =>{console.log(err); return;});
    }
  }

