
  import { Article } from '@/interfaces/arcticles/Article.entity';
  import { AuthenticationService } from '@/services/authentication.service';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import CustomImage from '../CustomImage.component.vue';
  import ArticleRubricList from './ArticleRubricList.component.vue';
  import {Utils} from '@/scripts/Utils';

  const maxDisplayedChars = 240;

  /**
   * @desc affichage en résumé d'un article
   */
  @Component({
    components: {
      'c-img' : CustomImage,
      'article-rubric-list': ArticleRubricList,
    },
  })
  export default class ArticleSum extends Vue {
    /**
     * @desc article à résumer
     */
    @Prop({default: () => {return new Article();}})
    article? : Article;
    
    /**
     * @desc si l'utilisateur est identifié -> s'il peut voir l'article et ses images
     */
    @Prop({default: false})
    identified? : boolean;

    /**
     * @desc retourne un string contenant le nom de l'auteur et la date de création
     */
    private getArticleDetails(authorName : string, createdAt : Date) : string {
      if(!authorName.length) authorName = 'inconnu';
      if(!createdAt) createdAt = new Date();

      let date = createdAt.getDate().toString();
      if(date.length === 1) date = '0' + date;
      let month = (createdAt.getMonth()+1).toString();
      if(month.length === 1) month = '0' + month;
      const strDate = date + '/' + month + "/" + createdAt.getFullYear();

      let outputTxt = 'Rédigé par ' + authorName;
      outputTxt += " le " + strDate;

      return outputTxt;
    }

    /**
     * @desc dermande de lecture de l'article
     * envoie soit vers la page de détails de l'article, soit vers la page de connexion
     */
    private readArticle() {
      if(!this.article) return;
      this.$router.push('/article/' + this.article.id);
    }
  }
