
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import MobileNavigationMenu from './MobileNavigationMenu.component.vue';

  const imgHeight = 275;
  const imgWidth = 787;

  /**
   * @desc boite d'outils en-tête pour l'affichage mobile du site
   */
  @Component({
    components: {
      'mobile-navigation-menu' : MobileNavigationMenu
    },
  })
  export default class MobileToolbar extends Vue {
    /**
     * @desc la hauteur de la boite à afficher
     */
    @Prop()
    headerHeight?: number;

    menuVisible = false;

    /**
     * @desc demande l'affichage du menu de navigation
     */
    private showMenu() : void {
      this.$root.$emit('show-menu', true);
    }
  }

