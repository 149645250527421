import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueRx from 'vue-rx';
import VuejsClipper from 'vuejs-clipper';
import VueMq from 'vue-mq';

Vue.config.productionTip = false;

Vue.use(VueMq, {
  breakpoints: {
    mobile: 450,
    tablet: 900,
    laptop: 1250,
    desktop: Infinity,
  }
});
Vue.use(VueRx);
Vue.use(VuejsClipper as any);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')


