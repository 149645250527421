
export class Utils 
{
  /**
   * @desc créé un string contenant une requète Url à partir des données envoyées
   * @param data données à transformer sous forme {arg1: valeur1, arg2: valeur2, ...}
   * @returns données sous forme : "arg1=valeur1&arg2=valeur2& ... " 
   */
  public static createUrlQuery(data : any) : string {
    const ret = [];
    const keys = Object.keys(data);
    const values = Object.values(data);
    for (let i=0; i < keys.length; ++i) {
      if(data[keys[i]] !== undefined)
        ret.push(keys[i] + '=' + encodeURIComponent(data[keys[i]]));
    }
    return ret.join('&');
  }


  /**
   * @desc vérifie si la date indiquée est la même année scolaire que la date actuelle
   * @param date date indiquée
   * @returns vrai si la même année
   */
  public static thisSchoolYearOrAfter(date : Date) : boolean {
    const today = new Date();
    const todayY = today.getFullYear();
    const todayMonth = today.getMonth() +1;
    const dateY = date.getFullYear();
    const dateMonth = date.getMonth() +1;

    if(dateY > todayY) {
      return true;
    }
    if(todayY === dateY) {
      if(todayMonth >= 9 && dateMonth >= 9) {
        return true;
      }
      else if (todayMonth < 9 && dateMonth < 9){
        return true;
      }
      return false;
    }
    else if(todayY === dateY-1){
      if(todayMonth >= 9 && dateMonth < 9) {
        return true;
      }
      return false;
    }
    else if(todayY === dateY+1){
      if(todayMonth < 9 && dateMonth >= 9) {
        return true;
      }
      return false;
    }

    return false;
  }

  /**
   * Donne la première année d'un anné scolaire pour une date donnée
   * Exemple: 20/02/2022 => 2021
   * @param date 
   * @returns 
   */
  public static getFirsYearOfSchoolYearFromDate(date: Date): number {
    const monthNumber = date.getMonth() + 1;
    // 9 Represente semptembre
    if(monthNumber >= 9) {
      return date.getFullYear();
    }
    return date.getFullYear() - 1;
  }

  /**
   * @desc ajoute un an scolaire (ou plus avec yearsToAdd) à la date indiquée
   * @param date la date indiquée
   * @param yearsToAdd les années supplémentaires à ajouter
   * @returns la date de la rentrée de l'année suivante
   */
  public static addSchoolYear(date : Date, yearsToAdd = 0) : Date {
    const resultDate = new Date(date);
    if(resultDate.getMonth() +1 >= 9) {
      resultDate.setFullYear(date.getFullYear() + 1 + yearsToAdd);
    }
    else{
      resultDate.setFullYear(date.getFullYear() + yearsToAdd);
    }
    resultDate.setMonth(7); // août
    resultDate.setDate(31);
    return resultDate;
  }

  /**
   * @desc renvoie le nom total d'une rubrique, avec (YYYY-YYYY+1) si la date de fin n'est pas l'année scolaire actuelle
   * @param startDate date de début de la rubrique
   * @param endDate date de fin de la rubrique
   * @param currentName nom de la rubrique
   * @returns le nom complet
   */
  public static getRubricFullName(startDate : Date, endDate : Date, currentName : string) : string {
    const startDateDate = new Date(startDate);
    const endDateDate = new Date(endDate);

    if(!Utils.thisSchoolYearOrAfter(endDateDate)) {
      const startYear = startDateDate.getFullYear();
      const endYear = endDateDate.getFullYear();
      if(startYear === endYear) currentName += '(' + startYear + ')';
      else currentName += " (" + startYear + '-' + endYear + ')';
    }
    return currentName;
  }
}