
  import ArticleList from '@/components/articles/ArticleList.component.vue';
  import HomeSlideshow from '@/components/home/HomeSlideshow.component.vue';
  import { AuthenticationService } from '@/services/authentication.service';
  import { Component, Vue } from 'vue-property-decorator';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import { Article } from '@/interfaces/arcticles/Article.entity';
  import axios from 'axios';
  import { Utils } from '@/scripts/Utils';


  @Component({
    components: {
      'home-slideshow': HomeSlideshow,
      'article-list' : ArticleList
    },
  })
  export default class Home extends Vue {
    allArticles : Array<Article> = [];
    stopLoading= false;
    loadArticlesCount = 5;

    mounted()  : void {
      this.loadAllArticles(new Date());
    }

    /**
     * @desc charge les articles limités au nombre "loadArticlesCount" plus anciens que la date "lastDate"
     */
    loadAllArticles(lastDate : Date) : void {
      if(this.stopLoading) return;

      const headers = AuthenticationService.getRequestHeader();
      const query = '?' + Utils.createUrlQuery({limit: this.loadArticlesCount})

      this.stopLoading = true;
      axios
        .get(  UrlConsts.getAllArticlesLimited + query, headers)
        .then((response) => {
          const responseArray = response.data;
          if(!responseArray || !responseArray.length || responseArray.length < this.loadArticlesCount) {
            this.stopLoading = true;
          }
          else {
            setTimeout(() => {
              this.stopLoading = false;
            }, 50);
          }
          this.allArticles = this.allArticles.filter((x: Article) => !responseArray.find((y : any) => y.id === x.id));
          this.allArticles = this.allArticles.concat(responseArray.map((x: any) => new Article(x)));
        })
        .catch(error => console.log(error))
    }
  }
