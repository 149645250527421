
/**
 * @desc format de données envoyés pour filtrer les articles
 */
export class ArticleFiltersDto {
  
  constructor(data ? : any) {
    if(data && data.rubrics && data.rubrics.length)
      this.rubrics = data.rubrics;
    if(data && data.years && data.years.length)
      this.years = data.years;
    if(data && data.startDate)
      this.startDate = data.startDate;
    if(data && data.endDate)
      this.endDate = data.endDate;
    if(data && data.searchText)
      this.searchText = data.searchText;
    if(data && data.unarchived)
      this.unarchived = data.unarchived;
  }

  rubrics?: Array<number>;
  years?: Array<number>;

  startDate?: Date;
  endDate?: Date;

  searchText? : string;

  wantedEndDate? : Date;

  limit?: number;
  unarchived? = false;
}