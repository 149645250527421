
  import { HeaderMenu } from '@/interfaces/HeaderMenu';
  import { Rubric } from '@/interfaces/Rubric.interface';
  import axios from 'axios';
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import * as NavHeaderConsts from '@/scripts/NavHeaderConsts';
  import { AuthenticationService } from '@/services/authentication.service';
  import { Utils } from '@/scripts/Utils';
  import Container from './Container.component.vue';
  import * as Consts from '@/scripts/Consts';

  const rubricType = 1;
  const classType = 2;

  /**
   * @desc menu de navigation pour l'affichage mobile
   */
  @Component({
    components: {
      'container' : Container
    },
  })
  export default class MobileNavigationMenu extends Vue {
    visible = false;

    username = ''; // nom de l'utilisateur connecté
    userStatus = '';
    myAccountHiddenList = '';
    idCount = 30;

    @Prop()
    menuVisible? : boolean;
    
    menus : Array<HeaderMenu> = [      
      new HeaderMenu({title: "Accueil", icon: "home", route:"/", id: 1}), 
      new HeaderMenu({title: "Articles", icon: "book-variant", route:"/articles", id: 2}), 
      new HeaderMenu({title: "Classes", icon: "school", route:"/classes", id: 3}),
      new HeaderMenu({title: "Projets d'école", icon: "google-classroom", route:"/projets-ecole", id: 4}),
      new HeaderMenu({title: "Archives", icon: "archive", route:"/archives", id: 5}),
      new HeaderMenu({title: "Contact", icon: "card-account-mail", route:"/contact", id: NavHeaderConsts.CONTACT_MENU}),
    ]

    accountMenu : any = {
      title: "Connexion",
      icon: "login-variant",
      route: "/connexion",
      content: false
    }

    mounted() : void {
      this.init();

      this.$root.$on('login', () => {
        this.initAccountMenu();
      });
      this.$root.$on('logout', () => {
        this.initAccountMenu();
      });
    }

    /**
     * @desc affiche le menu de navigation
     */
    @Watch('menuVisible')
    showMenu() : void {
      this.visible = false;
      setTimeout(() => {
        this.visible = true;
      }, 10);
    }

    /**
     * @desc initialise le menu de navigation
     */
    private init() : void{
      this.loadArticles();
      this.loadUnarchivedRubrics();
      this.initAccountMenu();
    }

    resetMenus() {
      this.menus = this.menus.filter(
        x => [1, 2, 3, 4, 5, 6].includes(x.id)
      );
    }

    /**
     * @desc initialiser le menu en fonction de l'utilisateur connecté
     */
    private initAccountMenu() : void {
      this.resetMenus();
       if(AuthenticationService.isLoggedIn()) { // utilisateur connecté
        const userStatus = AuthenticationService.getUserStatus();
        switch(userStatus) {
          case Consts.user_status_classroom:  // en tant que classe
            this.showClassroomAccountMenu();
            break;
            
          case Consts.user_status_parents:  // en tant que parent
            this.showParentsAccountMenu();
            break;

          case Consts.user_status_teacher:  // en tant qu'enseignant
            this.showTeacherAccountMenu();
            break; 

          case Consts.user_status_admin:  // en tant qu'admin
            this.showAdminAccountMenu();
            break; 

          default:
            this.showUnloggedAccountMenu();
            break;
        }
      }
      else {
        this.showUnloggedAccountMenu();
      }
    }

    /**
     * @desc charge derniers articles publiés
     */
    private loadArticles() : void {
      const headers = AuthenticationService.getRequestHeader();
      const query = '?' + Utils.createUrlQuery({limit: 5, startDate: new Date().toString()})

      axios
        .get(UrlConsts.getAllArticlesLimited + query, headers)
        .then((response) => {
          const articlesMenu = this.getMenu(NavHeaderConsts.ARTICLES_MENU);
          const articleMenus = [];
          articleMenus.push(
            new HeaderMenu(
              {title: "-- Voir tout --", id: this.idCount, route: `/articles`}
            ));
          for(let i=0; i < response.data.length; ++i) {
            ++this.idCount;
            articleMenus.push(
              new HeaderMenu(
                {title: response.data[i].title, id: this.idCount, route: `/article/${response.data[i].id}`}
              )
            );
          } 
          articlesMenu.children = articleMenus;
        })
        .catch(error => console.log(error))
    }

    /**
     * @desc charge les rubriques non archivées
     */
    private loadUnarchivedRubrics() : void {
      const headers = AuthenticationService.getRequestHeader();

      axios
        .get(UrlConsts.getAllUnarchivedRubrics, headers)
        .then((response) => {
          const rubrics = response.data as Rubric[];
          const rubricMenus = [];
          const classMenus = [];
          rubricMenus.push(
            new HeaderMenu(
              {title: "-- Voir tout --", id: this.idCount, route: `/projets-ecole`}
            ));
          classMenus.push(
            new HeaderMenu(
              {title: "-- Voir tout --", id: this.idCount, route: `/classes`}
            ));
          for(let i=0; i < rubrics.length; ++i) {
            if(rubrics[i].type === rubricType) {
              ++this.idCount;
              rubricMenus.push(
                new HeaderMenu(
                  {title: rubrics[i].name, id: this.idCount, route: `/rubriques/articles/${rubrics[i].id}`}
                ));
            }
            else if(rubrics[i].type === classType) {
              ++this.idCount;
              classMenus.push(
                new HeaderMenu(
                  {title: rubrics[i].name, id: this.idCount, route: `/rubriques/articles/${rubrics[i].id}`}
                )
              );
            }
          }

          const rubricsMenu = this.getMenu(NavHeaderConsts.PROJECTS_MENU);
          const classesMenu = this.getMenu(NavHeaderConsts.CLASSES_MENU);
          rubricsMenu.children = rubricMenus;
          classesMenu.children = classMenus;
        })
        .catch(error => console.log(error))
    }

    /**
     * @desc renvoie le menu demandé
     */
    private getMenu(id: number) : HeaderMenu {
      return this.menus.find(x => x.id === id) || new HeaderMenu();
    }

    /**
     * @desc ajoute un menu avant le menu indiqué
     */
    private insertMenuBefore(id: number, menu: HeaderMenu) : void {
      if(id === -1)
        this.menus.push(menu);
      else {
        const index = this.menus.findIndex(x => x.id === id);
        if(index > -1)
          this.menus.splice(index, 0, menu);
      }
    }

    /**
     * @desc supprime le menu indiqué
     */
    private removeMenu(id: number) : void {
      const index = this.menus.findIndex(x => x.id === id);
      if(index > -1)
        this.menus.splice(index, 1);
    }

    /**
     * @description montre le composant pour un utilisateur non-connecté
     */
    showUnloggedAccountMenu() : void {
      this.accountMenu.title =  "Connexion";
      this.accountMenu.icon = "login";
      this.accountMenu.route = "/connexion";
      this.removeMenu(NavHeaderConsts.ADMIN_MENU);
      this.removeMenu(NavHeaderConsts.CLASS_MENU);

      this.username = '';
      this.userStatus = "";
      this.accountMenu.content = false;
    }

    /**
     * @description montre le composant pour un utilisateur connecté en tant qu'enseignant
     */
    showTeacherAccountMenu() : void {
      this.accountMenu.title =  "Déconnexion";
      this.accountMenu.icon = "logout";
      this.accountMenu.route = undefined;

      const adminMenu = new HeaderMenu({title: "Administration", icon:"shield-edit", id: NavHeaderConsts.ADMIN_MENU, children: [
          new HeaderMenu({title: "Créer article", route: '/gestion/creer-article', id: NavHeaderConsts.ADMIN_CREATE_ARTICLE_MENU}),
          new HeaderMenu({title: "Ma classe", route: '/gestion/ma-classe', id: NavHeaderConsts.ADMIN_MY_CLASSROOM_MENU}),
          new HeaderMenu({title: "Projets d'école", route: '/gestion/projets-ecole', id: NavHeaderConsts.ADMIN_SCHOOL_PROJECTS_MENU}),
          new HeaderMenu({title: "Gestion des classes", route: '/gestion/classes', id: NavHeaderConsts.ADMIN_CLASSROOMS_MENU}),
          new HeaderMenu({title: "Gestion d'accueil", route: '/gestion/accueil', id: NavHeaderConsts.ADMIN_HOME_MENU}),
          new HeaderMenu({title: "Statistiques", route: '/gestion/statistiques', id: NavHeaderConsts.ADMIN_STAT_MENU})
        ]
      });
      this.insertMenuBefore(-1, adminMenu);

      this.username = AuthenticationService.getUsername();
      this.userStatus = "Enseignant";
      this.accountMenu.content = true;
    }

     /**
     * @description montre le composant pour un utilisateur connecté en tant qu'admin
     */
    showAdminAccountMenu() : void {
      this.accountMenu.title =  "Déconnexion";
      this.accountMenu.icon = "logout";
      this.accountMenu.route = undefined;

      const adminMenu = new HeaderMenu({title: "Administration", icon:"shield-edit", id: NavHeaderConsts.ADMIN_MENU, children: [
          new HeaderMenu({title: "Projets d'école", route: '/gestion/projets-ecole', id: NavHeaderConsts.ADMIN_SCHOOL_PROJECTS_MENU}),
          new HeaderMenu({title: "Gestion des classes", route: '/gestion/classes', id: NavHeaderConsts.ADMIN_CLASSROOMS_MENU}),
          new HeaderMenu({title: "Gestion d'accueil", route: '/gestion/accueil', id: NavHeaderConsts.ADMIN_HOME_MENU}),
          new HeaderMenu({title: "Statistiques", route: '/gestion/statistiques', id: NavHeaderConsts.ADMIN_STAT_MENU}),
          new HeaderMenu({title: "Gestion des enseignants", route: '/gestion/enseignants', id: NavHeaderConsts.ADMIN_TEACHERS_MENU}),
        ]
      });
      this.insertMenuBefore(-1, adminMenu);

      this.username = AuthenticationService.getUsername();
      this.userStatus = "Administrateur";
      this.accountMenu.content = true;
    }

    /**
     * @description montre le composant pour un utilisateur connecté en tant que classe
     */
    showClassroomAccountMenu() : void {
      this.accountMenu.title =  "Déconnexion";
      this.accountMenu.icon = "logout";
      this.accountMenu.route = undefined;

      const classMenu = new HeaderMenu({title: "Création", id: NavHeaderConsts.CLASS_MENU, children: [
          new HeaderMenu({title: "Créer article", route: '/classe/creer-article', id: NavHeaderConsts.CLASS_CREATE_ARTICLE_MENU}),
          new HeaderMenu({title: "Mes articles", route: '/classe/creer-article', id: NavHeaderConsts.ADMIN_MY_CLASSROOM_MENU}),
        ]
      });

      this.username = AuthenticationService.getUsername();
      this.userStatus = "Classe";
      this.accountMenu.content = true;

      this.insertMenuBefore(-1, classMenu);

      this.username = AuthenticationService.getUsername();
    }

    /**
     * @description montre le composant pour un utilisateur connecté en tant que parent
     */
    showParentsAccountMenu() : void {
      this.accountMenu.title =  "Déconnexion";
      this.accountMenu.icon = "logout";
      this.accountMenu.route = undefined;

      this.username = '';
      this.userStatus = "Parent";
      this.accountMenu.content = true;
    }

    /**
     * @desc demande la déconnexion de l'utilisateur
     */
    accountButtonClick() : void {
      if(AuthenticationService.isLoggedIn()) {
        this.disconnect();
      }
    }

    /**
     * @description déconnecte l'utilisateur et l'envoie vers la page de connexion
     */
    disconnect() :void  {
      AuthenticationService.logout();
      console.log("deconnexion");
      this.initAccountMenu();
      this.$router.push("/connexion").catch((err) =>{console.log(err); return;});
    }

  }

