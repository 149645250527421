
  import { Component, Vue } from 'vue-property-decorator';
  import axios from 'axios';
  import { AuthenticationService } from '../../services/authentication.service';
  import { SlideshowImage } from '@/interfaces/SlideshowImage';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import CustomImage from '../CustomImage.component.vue';

  import { VueperSlides, VueperSlide } from 'vueperslides'
  import 'vueperslides/dist/vueperslides.css'

  /**
   * @desc composant affichant les images du diaporama
   */
  @Component({
    components: {
      'c-img': CustomImage,
      VueperSlides,
      VueperSlide,
    },
  })
  export default class HomeSlideshow extends Vue {
    
    slideshowImages : Array<SlideshowImage> = []
    thumbnailCount = 5;
    
    mounted(): void {
      if((this as any).$mq === 'mobile' || (this as any).$mq === 'tablet') 
        this.thumbnailCount = 3;
      this.loadData();
    }

    /**
     * @desc charge les images du diaporama
     */
    private loadData() : void {
      const headers = AuthenticationService.getRequestHeader();

      axios
        .get(UrlConsts.getAllSlideshowImgs, headers)
        .then((response) => {
          this.slideshowImages = response.data
            .sort((a : any, b : any) => a.order- b.order)
            .map((x: any) => 
              {
                return {
                  title: x.title,
                  content: `
                    <c-img imgId="${x.id}" />
                  `,
                  id: x.id
                };
              }
            );
        })
        .catch(error => console.log(error))
    }
  }
