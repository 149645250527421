
/**
 * @desc filtre 
 */
export class Filter {  
  constructor(data ? : any) {
    this.title = data && data.title || "filtre";
    this.key = data && data.key || "";

    if(data && data.children)
      this.children = data.children;
  }

  title: string;
  key: string;

  children? : Filter[];
}