
  import { AuthenticationService } from '@/services/authentication.service';
import { Component, Prop, Vue } from 'vue-property-decorator';

  /**
   * @desc boite affichant les propriétés de l'utilisateur connecté
   */
  @Component({
    components: {},
  })
  export default class UserAccount extends Vue {
    @Prop()
    username? : string;

    @Prop()
    userStatus? : string;

    /**
     * @desc déconnecte l'utilisateur
     */
    private disconnect() : void {
      AuthenticationService.logout();
      this.$root.$emit("logout");
      this.$router.push("/connexion").catch((err) =>{console.log(err); return;});
    }
  }

