
  import { Component, Vue } from 'vue-property-decorator';
  import PageHeader from '@/components/PageHeader.component.vue';
  import PageFooter from '@/components/PageFooter.component.vue';
  import NavHeader from './components/NavHeader.component.vue';
  import MobileToolbar from './components/MobileToolbar.component.vue';
  import MobileNavigationMenu from './components/MobileNavigationMenu.component.vue';
  import MobileArticleFilter from './components/filtering/MobileArticleFilter.component.vue';
  import {AuthenticationService} from '@/services/authentication.service';
  import axios from 'axios';
  import * as Consts from '@/scripts/Consts';

  const imgHeight = 275;
  const imgWidth = 787;

  @Component({
    components: {
      'page-header': PageHeader,
      'PageFooter': PageFooter,
      'nav-header' : NavHeader,
      'mobile-toolbar' : MobileToolbar,
      'mobile-navigation-menu' : MobileNavigationMenu,
      'mobile-article-filter' : MobileArticleFilter
    },
  })
  export default class App extends Vue {
    headerHeight = imgHeight;

    menuVisible = false;
    filterMenuVisible = false;

    townBackgroundSrc = '/img/town background.jpg';

    mounted() : void {
      if( (this as any).$mq === 'mobile' || (this as any).$mq === 'tablet') {
        this.townBackgroundSrc = '/img/town background mobile.jpg';
        this.headerHeight = imgHeight * (window.outerWidth / imgWidth);
        setTimeout(() => {
          (this.$el.querySelector(".page-box") as HTMLElement).style.marginTop = (this.headerHeight + 30) + "px";
        }, 200);
      }
      this.$root.$on('show-menu', (value : boolean) => {
        this.showMenu(value);
      });
      this.$root.$on('show-filter-menu', (value : boolean) => {
        this.showFilterMenu(value);
      });

      this.updateAuthentication();
    }

    showMenu(value : boolean) : void {
      this.menuVisible = !value;
      setTimeout(() => {
        this.menuVisible = value;
      });
    }

    showFilterMenu(value : boolean) : void {
      this.filterMenuVisible = !value;
      setTimeout(() => {
        this.filterMenuVisible = value;
      });
    }


    updateAuthentication() : void {
      setTimeout(() => {
        try {
          AuthenticationService.checkTokenValidation(axios).then((response) => {
            if(response === 1) {
              this.$root.$emit('login');
            }
            else if(response === 2) {
              this.$root.$emit('logout');
              this.$router.push('/connexion');
            }
          });
        } catch(err) {
          console.error("err", err);
        }
                
        this.updateAuthentication();
      }, 3000);
    }
  }
