
  import { Component, Vue } from 'vue-property-decorator';

  /**
   * @desc composant de pied de page
   */
  @Component({
    components: {},
  })
  export default class PageFooter extends Vue {
    name = 'page-footer';
  }
