import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);


const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/connexion',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/articles',
    name: 'ArticlesView',
    component: () => import('../views/ArticlesView.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/gestion/projets-ecole',
    name: 'SchoolProjectsAdmin',
    component: () => import('../views/admin/SchoolProjectsAdmin.vue')
  },
  {
    path: '/gestion/classes',
    name: 'ClassroomsAdmin',
    component: () => import('../views/admin/ClassroomsAdmin.vue')
  },
  {
    path: '/gestion/ma-classe',
    name: 'MyClassroom',
    component: () => import('../views/admin/MyClassroom.vue')
  },
  {
    path: '/gestion/accueil',
    name: 'HomeAdmin',
    component: () => import('../views/admin/HomeAdmin.vue')
  },
  {
    path: '/gestion/statistiques',
    name: 'Statistics',
    component: () => import('../views/admin/Statistics.vue')
  },
  {
    path: '/gestion/creer-article',
    name: 'CreateArticleAdmin',
    component: () => import('../views/admin/CreateArticleAdmin.vue')
  },
  {
    path: '/gestion/enseignants',
    name: 'TeachersAdmin',
    component: () => import('../views/admin/TeachersAdmin.vue')
  },
  {
    path: '/gestion/parents',
    name: 'ParentsAdmin',
    component: () => import('../views/admin/ParentsAdmin.vue')
  },
  {
    path: '/article/:id',
    name: 'ArticleDetailsView',
    component: () => import('../views/ArticleDetailsView.vue')
  },
  {
    path: '/article/edition/:id',
    name: 'ArticleEditionView',
    component: () => import('../views/ArticleEditionView.vue')
  },
  {
    path: '/validation',
    name: 'ValidationView',
    component: () => import('../views/ValidationView.vue')
  },

  {
    path: '/classe/creer-article',
    name: 'CreateArticleClassroom',
    component: () => import('../views/classroom-pages/CreateArticleClassroom.vue')
  },
  {
    path: '/classe/mes-articles',
    name: 'MyArticles',
    component: () => import('../views/classroom-pages/MyArticles.vue')
  },
  {
    path: '/classe/edition/:id',
    name: 'ArticleEditionView',
    component: () => import('../views/ArticleEditionView.vue')
  },
  {
    path: '/rubriques/articles/:rubricId',
    name: 'RubricArticles',
    component: () => import('../views/RubricArticles.vue')
  },
  {
    path: '/classes',
    name: 'Classes',
    component: () => import('../views/Classes.vue')
  },
  {
    path: '/projets-ecole',
    name: 'SchoolProjects',
    component: () => import('../views/SchoolProjects.vue')
  },

  {
    path: '/archives',
    name: 'Archives',
    component: () => import('../views/Archives.vue')
  },  

  {
    path: '*',
    name: 'NotFoundView',
    component: () => import('../views/NotFoundView.vue')
  },  
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
