
/**
 * @desc données d'un menu de l'en-tête
 */
export class HeaderMenu {
  
  constructor(data ? : any) {
    this.title = data && data.title || "";
    this.id = data && data.id || 0;

    if(data && data.route)
      this.route = data.route;
    if(data && data.icon)
      this.icon = data.icon;
    if(data && data.children)
      this.children = data.children;
  }

  title = "";
  route?: string | any;
  icon? : string;
  children?: Array<HeaderMenu> = [];
  id = 0;
}