import { ArticleImageDto } from "./Article.entity";

/**
 * @desc object de transfert des données d'édition d'un article au serveur.
 * ne contient que les propriétés indiquées
 */
export class ArticleEditionDto {
  constructor(data ? : any) {
    this.id = data && data.id || 0;
    if(Object.keys(data).length > 1) {
      this.changed = true;
    } 
    this.imgs = data && data.imgs;
    this.title = data && data.title;
    this.summary = data && data.summary;
    this.textContent = data && data.textContent;
    this.authorName = data && data.authorName;
    this.rubrics = data && data.rubrics;
    this.coverImgId = data && data.coverImgId;
  }

  id: number;
  imgs?: Array<ArticleImageDto> | undefined; // ids
  title?: string;
  summary?: string;
  textContent?: string;
  authorName?: string;
  rubrics?: Array<number>;
  coverImgId? : number;
  changed = false;
  
  public clone(): any {
    const cloneObj = new ArticleEditionDto() as any;
    for (const attribut in this) {
      cloneObj[attribut] = this[attribut];
    }
    return cloneObj;
  }
}