
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';


  /**
   * @desc barre de recherche pour filtrer les articles
   */
  @Component({
    components: {},
  })
  export default class SearchBar extends Vue {
    @Prop({default: "recherche"})
    placeholder? : string;

    searchText = "";

    /**
     * @desc envoie un msg événement pour indiquer un changement dans le texte inscrit
     */
    searchTextChanged() : void {
      this.$emit('filterSearchText', this.searchText);
    }
  }
