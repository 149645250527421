import { LoginValidationDto } from "@/interfaces/LoginValidationDto";
import * as UrlConsts from '@/scripts/UrlConsts';

export class AuthenticationService {

  /**
   * @desc initialise la session d'un utilisateur
   * @param loginValidationDto données de session
   */
  static setSession(loginValidationDto : LoginValidationDto) : void {
    localStorage.setItem('accessToken', loginValidationDto.token);
    localStorage.setItem('expiresAt', loginValidationDto.expiresAt.toString());
    localStorage.setItem('username', loginValidationDto.username);
    localStorage.setItem('userStatus', loginValidationDto.status.toString());
  }

  /**
   * supprime les données de session stockées en front
   */
  static logout() : void {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('expiresAt');
    localStorage.removeItem('username');
    localStorage.removeItem('userStatus');
  }
  
  /**
   * @desc vérifie si l'utilisateur est connecté
   */
  static isLoggedIn() : boolean {
    if(!this.getToken()) return false;
    return !this.isTokenExpired();
  }

  /**
   * @desc vérifie si l'utilisateur est déconnecté
   */
  static isLoggedOut() : boolean {
    return !this.isLoggedIn();
  }

  /**
   * @desc vérifie si le token est expiré
   */
  static isTokenExpired() : boolean {
    const expiresAt = localStorage.getItem("expiresAt");
    if(!expiresAt) return false;

    return new Date(expiresAt).getTime() - Date.now() < 0;
  }

    /**
   * @desc vérifie si le token va expirer
   */
     static isTokenNearToExpiration() : boolean {
      const expiresAt = localStorage.getItem("expiresAt");
      if(!expiresAt) return false;
  
      return new Date(expiresAt).getTime() - Date.now() < 10 * 60 * 1000;
    }

  /**
   * @desc renvoie le token stocké en front
   */
  static getToken() : string {
    return localStorage.getItem("accessToken") as any;
  }

  /**
   * @desc renvoie l'header contenant le token de connexion
   * @returns 
   */
  static getRequestHeader() : any {
    const token = AuthenticationService.getToken();
    return {headers: { Authorization: `Bearer ${token}` }}
  }

  /**
   * @desc renvoie le nom de l'utilisateur connecté
   * @returns 
   */
  static getUsername() : string {
    return localStorage.getItem("username") as any;
  }

  /**
   * @desc renvoie le statut de l'utilisateur connecté
   * @returns 
   */
  static getUserStatus(): number {
    return +(localStorage.getItem("userStatus") ?? 0);
  }


  static async checkTokenValidation(axios : any) : Promise<number> {
    const currentToken = this.getToken();
    if(!currentToken) return 0;

    return new Promise((resolve) => {
      if(this.isTokenNearToExpiration()) {
        const headers = AuthenticationService.getRequestHeader();
  
        axios
          .post(UrlConsts.refreshToken, {token : currentToken}, headers)
          .then((response : any) => {
            AuthenticationService.setSession(response.data);
            resolve(1);
          })
          .catch((error : any) => {
            if(error.response && error.response.status === 401) {
              this.logout();
              resolve(2);
            }
            resolve(0);
          })
      }
    });
  }
}