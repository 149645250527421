
  export const serverUrl = process.env.VUE_APP_SERVER_URL;

  ///////       AUTHENTICATION
  export const login = serverUrl + "/authentication/login";
  export const refreshToken = serverUrl + "/authentication/refresh-token";

  ///////      IMAGES 
  export const saveImg = serverUrl + "/images/save-img";
  export const getImg = serverUrl + "/images";

  ///////      ARTILCE IMAGES 
  export const getArticleImg =  serverUrl +  "/images/articles";
  export const saveArticleImg =  serverUrl +  "/images/articles/save-img";
  export const setImgOrder =  serverUrl +  "/images/articles/order";
  export const rotateArticleImg =  serverUrl +  "/images/articles/rotate";
  export const deleteArticleImg =  serverUrl +  "/images/articles";
  export const saveCoverImg =  serverUrl +  "/images/articles/save-cover-img";

  ///////      SLIDESHOW IMAGES 
  export const getAllSlideshowImgs = serverUrl + "/images/slideshow/all";
  export const createSlideshowImg = serverUrl + "/images/slideshow/create";
  export const editSlideshowImg = serverUrl + "/images/slideshow/edit";
  export const orderSlideshowImg = serverUrl + "/images/slideshow/order";
  export const deleteSlideshowImg = serverUrl + "/images/slideshow/delete";

  ///////      RUBRICS 
  export const getAllRubrics = serverUrl +  "/rubrics/all";
  export const getAllUnarchivedRubrics = serverUrl +  "/rubrics/all/unarchived";
  export const getRubric = serverUrl +  "/rubrics";
  export const getAllArchivedRubrics = serverUrl +  "/rubrics/all/archived";

  ///////      SCHOOL PROJETCS 
  export const getAllProjects =  serverUrl +  "/school-projects/all";
  export const getAllUnarchivedProjects =  serverUrl +  "/school-projects/all/unarchived";
  export const createProject =  serverUrl +  "/school-projects/create";
  export const editProject =  serverUrl +  "/school-projects/edit";
  export const deleteProject =  serverUrl +  "/school-projects/delete";

  ///////      CLASSROOMS
  export const getAllClassrooms =  serverUrl +  "/classrooms/all";
  export const getAllUnarchivedClassrooms =  serverUrl +  "/classrooms/all/unarchived";
  export const createClassroom =  serverUrl +  "/classrooms/create";
  export const editClassroom =  serverUrl +  "/classrooms/edit";
  export const deleteClassroom =  serverUrl +  "/classrooms/delete";

  ///////      TEACHERS
  export const getAllTeachers =  serverUrl +  "/teachers/all";
  export const getAllTeachersWithUsers = serverUrl +  "/teachers/all-with-users";
  export const createTeacher = serverUrl +  "/teachers/create";
  export const editTeacher = serverUrl +  "/teachers/edit";

  ///////      PARENTS
  export const getAllParents =  serverUrl +  "/parents/all";
  export const createParent = serverUrl +  "/parents/create";
  export const editParent = serverUrl +  "/parents/edit";

  ///////      ARTICLES
  export const getArticle =  serverUrl +   "/articles";
  export const getAllArticles =  serverUrl +  "/articles/all";
  export const getAllArticlesFiltered = serverUrl + "/articles/all/filtered";
  export const getMyClassroomArticles =  serverUrl +  "/articles/my-classrooms";
  export const getMyArticles =  serverUrl +  "/articles/my-articles";
  export const getAllArticlesLimited =  serverUrl +  "/articles/all/limit";
  export const getAllRubricArticlesLimited = serverUrl +  "/articles/rubric/limit";

  export const getAllDraftArticles = serverUrl +  "/articles/draft";

  export const validateArticle = serverUrl +   "/articles/validate";
  export const createArticle =  serverUrl +  "/articles/create";
  export const createUnvalidatedArticle =  serverUrl +  "/articles/create-unvalidated";
  export const editArticle =  serverUrl +  "/articles/edit";
  export const deleteArticle =  serverUrl +  "/articles";


  ///////      VISITS
  export const getAllVisits = serverUrl + "/visits/all";