
export const ARTICLES_MENU = 2;
export const CLASSES_MENU = 3;
export const PROJECTS_MENU = 4;
export const ARCHIVES_MENU = 5;
export const CONTACT_MENU = 6;
export const ACCOUNT_MENU = 7;

export const ADMIN_MENU = 11;
export const ADMIN_CREATE_ARTICLE_MENU = 12;
export const ADMIN_MY_CLASSROOM_MENU = 13;
export const ADMIN_SCHOOL_PROJECTS_MENU = 14;
export const ADMIN_CLASSROOMS_MENU = 15;
export const ADMIN_HOME_MENU = 16;
export const ADMIN_STAT_MENU = 17;

export const ADMIN_TEACHERS_MENU = 18;
export const ADMIN_PARENTS_MENU = 19;

export const CLASS_MENU = 21;
export const CLASS_CREATE_ARTICLE_MENU = 22;
export const CLASS_MY_ARTICLES_MENU = 23;
